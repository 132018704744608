<template>
  <div
    class="userguanli"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- <div class="sousuo">
      <div class="div1">
        <span>菜单名称</span>
        <el-select class="select" v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="div1">
        <span>部门</span>
        <el-select class="select" v-model="value" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="div1">
        <span>关键字</span>

        <el-select
          style="width: 100px"
          class="select"
          v-model="value"
          placeholder="ID"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <input class="inputs" type="text" />
      </div>
      <img class="imgs" src="../../assets/images/query.png" alt />
    </div> -->
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <!-- <el-table-column prop="id" label="ID" width="120"></el-table-column> -->

        <el-table-column prop="id" label="ID"></el-table-column>
        <!-- <el-table-column prop="photoUrl" label="头像路径" width="180"></el-table-column> -->
        <el-table-column
          prop="sys"
          label="所属系统"
          width="150"
        ></el-table-column>
        <el-table-column prop="imei" label="IMEI"></el-table-column>
        <el-table-column prop="number" label="企业编号"></el-table-column>
        <!-- <el-table-column
          prop="entryDate"
          label="入职日期"
          width="150"
          :formatter="formatter"
        >
        </el-table-column> -->
        <!-- <el-table-column
          prop="quitDate"
          label="离职日期"
          width="150"
          :formatter="formatter"
        >
        </el-table-column> -->
        <el-table-column prop="name" label="账号"> </el-table-column>
        <el-table-column prop="password" label="密码"> </el-table-column>
        <el-table-column prop="auto" label="是否自动登录"> </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column prop="caozuo" label="操作">
          <template slot-scope="scope">
            <img
              class="buts"
              src="../../assets/images/edits.png"
              @click="changeUser(scope.$index, scope.row)"
              alt
            />
            <img
              class="buts"
              src="../../assets/images/del.png"
              @click="handleEdit(scope.$index, scope.row)"
              alt
            />
            <!-- <el-button
              class="details"
              size="mini"
              @click="changeUser(scope.$index, scope.row)"
              >修改</el-button
            > -->
            <!-- <el-button
              class="details"
              size="mini"
              @click="handleEdit(scope.$index, scope.row)"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="totals">
      <!-- 左 -->
      <div class="left">
        <!-- <p>
          总用户数
          <span class="spanto">{{ totalCount }}</span
          >人
        </p> -->
        <div class="buttons">
          <el-button type="text" @click="add">添加</el-button>
        </div>
      </div>
      <!-- 右 -->
      <div class="total">
        <!-- 分页 -->
        <div class="pages">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage1"
            :page-size="this.pageSize"
            layout="total, prev, pager, next"
            :total="this.totalCount"
          >
            <img class="lefts" src="../../assets/images/leftpage.png" alt />
            <img class="rights" src="../../assets/images/rightpage.png" alt />
          </el-pagination>
        </div>
        <!-- 按钮 -->
      </div>
    </div>

    <!-- 增加弹框-->
    <div class="accountadd">
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="35%"
        :before-close="clearUserForm"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <!-- <el-form-item label="ID" prop="name">
            <el-input
              v-model="ruleForm.userName"
              style="width: 196px"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="所属系统" prop="name">
            <el-input v-model="ruleForm.sys" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="企业编号" prop="name">
            <el-input v-model="ruleForm.number" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="IMEI" prop="name">
            <el-input v-model="ruleForm.imei" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="账号" prop="name">
            <el-input v-model="ruleForm.name" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="name">
            <el-input
              v-model="ruleForm.password"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否自动登录" prop="">
            <el-select v-model="ruleForm.auto" placeholder="请选择">
              <el-option
                v-for="item in options5"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="name">
            <el-input
              type="textarea"
              v-model="ruleForm.remark"
              style="width: 196px"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clearUserForm">取 消</el-button>
          <el-button type="primary" @click="addUserList">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 修改弹框-->
    <div class="accountadd">
      <el-dialog
        :title="title"
        :visible.sync="dialogVisibleChange"
        width="35%"
        :before-close="clearUserFormChange"
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <!-- <el-form-item label="ID" prop="name">
            <el-input
              v-model="ruleForm.userName"
              style="width: 196px"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="所属系统" prop="name">
            <el-input v-model="ruleForm.sys" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="企业编号" prop="name">
            <el-input v-model="ruleForm.number" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="IMEI" prop="name">
            <el-input v-model="ruleForm.imei" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="账号" prop="name">
            <el-input v-model="ruleForm.name" style="width: 196px"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="name">
            <el-input
              v-model="ruleForm.password"
              style="width: 196px"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否自动登录" prop="">
            <el-select v-model="ruleForm.auto" placeholder="请选择">
              <el-option
                v-for="item in options5"
                :key="item.code"
                :label="item.name"
                :value="item.code"
                clearable
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="name">
            <el-input
              type="textarea"
              v-model="ruleForm.remark"
              style="width: 196px"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clearUserFormChange">取 消</el-button>
          <el-button type="primary" @click="changeUserLs">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 二次弹框 -->
    <el-dialog title="提示" :visible.sync="dialogVisibleTwo" width="30%">
      <span>确认删除此信息吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleTwo = false">取 消</el-button>
        <el-button type="primary" @click="deleteTwo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import rolemanage from "../system_maintain/rolemanage";
// import addaccount from "../system_maintain/addaccount";
import { api } from "../../api1/config";
import { apiShout } from "../../api1/configShout";
import moment from "moment";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      twoID: "",
      isCurs: false,
      // 弹框
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisibleChange: false,
      dialogVisibleTwo: false,
      title: "添加",
      ruleForm: {
        id: "",
        remark: "",
        auto: "",
        password: "",
        name: "",
        number: "",
        imei: "",
        sys: "",
      },
      currentPage1: 1,
      pageSize: 10,
      totalCount: 1000,
      pageCount: "", //网络请求的页码
      options: [
        {
          value: "选项1",

          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      value5: "",
      value6: "",
      options1: [],
      options2: [],
      options3: [],
      options4: [],
      options5: [],
      optionsCus: [],
      optionsserviceType: [],
      optionspost: [],
      optionsbindTelType: [],
      value: "",
      orgIds: null,
      //   table
      tableData: [],
      rules: {
        userPass: [
          { required: true, message: "请输入ID名称", trigger: "blur" },
          {
            min: 6,
            max: 12,
            message: "长度在 6 到 12 个字符",
            trigger: "blur",
          },
        ],
      },
      roIdAA: null,
      userId: "",
      flag: false,
      flagWS: false,
      flagsex: false,
      flagORG: false,
      flagROLE: false,
      flagcusSER: false,
      flagseverType: false,
      falgPOST: false,
      flagbindT: false,
      WS: "",
      US: "",
      XB: "",
      ORG: "",
      ROLE: "",
      CS: "",
      ST: "",
      PT: "",
      BT: "",
      roleValue: "",
    };
  },
  // components: {
  //   rolemanage,
  //   addaccount
  // },
  created() {
    this.menulist(1);
    this.workIng(); //查询大类下的小类
    this.searchAllJigou(); //查询所有机构
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //用户列表
    menulist(page) {
      apiShout.autoLogin({ pageCount: page }).then((res) => {
        console.log("列表:", res.data);
        if (res.data.code === 200) {
          this.tableData = res.data.result.data;
          this.totalCount = res.data.result.page.totalCount;
          this.pageSize = res.data.result.page.pageSize;
          this.tableData.forEach((ele) => {
            if (ele.auto == 1) {
              ele.auto = "是";
            } else if (ele.auto == 0) {
              ele.auto = "否";
            }
          });
        } else if (res.data.msg == "token不正确") {
          this.$router.push("/");
          this.$message.error("token不正确");
        }
      });
    },
    //添加用户
    add() {
      this.dialogVisible = true;
      this.title = "添加";
    },
    //添加用户
    addUserList() {
      if (this.title == "添加") {
        this.title == "添加";
        //判断电话号码
        if (!/^1[0123456789]\d{9}$/.test(this.ruleForm.name)) {
          this.$message.error("手机号格式不正确");
          return false;
        }
        // if (!/^(https:\/\/|http:\/\/)/.test(this.ruleForm.sys)) {
        //   this.$message.error("所属系统格式不正确！");
        //   return false;
        // }
        apiShout.insertLogin(this.ruleForm).then((res) => {
          console.log("添加用户：", res.data);
          if (res.data.code == 200) {
            this.dialogVisible = false;
            //添加成功
            this.$message({
              message: "恭喜你，添加用户成功",
              type: "success",
            });
            //清空表单数据
            this.clearUserForm();
            //刷新页面数据
            //console.log('ye:',this.currentPage1);
            this.menulist(this.currentPage1);
          } else {
            //添加成功
            this.$message({
              message: "很遗憾，添加失败",
              type: "success",
            });
            this.dialogVisible = false;
            //清空表单数据
            this.clearUserForm();
          }
        });
      }
    },
    //编辑用户
    changeUser(index, row) {
      this.dialogVisibleChange = true;
      console.log(index, row, "12221222");
      this.ruleForm = row;
      this.ruleForm.id = row.id;
      this.title = "编辑";
    },
    //编辑的确定
    changeUserLs() {
      if (this.ruleForm.auto == "是") {
        this.ruleForm.auto = "1";
      } else if (this.ruleForm.auto == "否") {
        this.ruleForm.auto = "0";
      }
      if (!/^1[0123456789]\d{9}$/.test(this.ruleForm.name)) {
        this.$message.error("手机号格式不正确");
        return false;
      }
      // let regular =
      //   /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
      // if (!regular.test(this.ruleForm.sys)) {
      //   this.$message.error("所属系统格式不正确！");
      //   return false;
      // }
      // if (!/^(https:\/\/|http:\/\/)/.test(this.ruleForm.sys)) {
      //   this.$message.error("所属系统格式不正确！");
      //   return false;
      // }
      let obj = {
        id: this.ruleForm.id,
        auto: this.ruleForm.auto,
        imei: this.ruleForm.imei,
        name: this.ruleForm.name,
        number: this.ruleForm.number,
        password: this.ruleForm.password,
        remark: this.ruleForm.remark,
        sys: this.ruleForm.sys,
      };
      // console.log("输出修改：", obj);
      apiShout.updateLogin(obj).then((res) => {
        console.log("修改用户:", res.data);
        if (res.data.code == 200) {
          this.dialogVisibleChange = false;
          //修改成功
          this.$message({
            message: "恭喜你，修改用户成功",
            type: "success",
          });
          //清空表单数据
          this.clearUserForm();
          //刷新页面数据
          //console.log('ye:',this.currentPage1);
          this.menulist(this.currentPage1);
        }
      });
    },
    //删除用户
    handleEdit(index, row) {
      console.log(index, row);
      this.dialogVisibleTwo = true;
      this.twoID = row.id;
    },
    deleteTwo() {
      this.dialogVisibleTwo = false;
      apiShout.deleteLogin({ id: this.twoID }).then((res) => {
        //console.log("删除用户:", res.data);
        if (res.data.code == 200) {
          //删除成功
          this.$message({
            message: "恭喜你，删除用户成功",
            type: "success",
          });
          //清空表单数据
          this.clearUserForm();
          //刷新页面数据
          this.menulist(this.currentPage1);
        }
      });
    },
    //关闭详情按钮
    detailsX() {
      this.dialogVisible1 = false;
      //清空表单数据
      this.clearUserForm();
    },
    //时间戳转换
    formatter(row) {
      // console.log('行',row,'列',column);
      let date = row.createDate;
      return moment(date).format("YYYY-MM-DD");
      //return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    formatter1(row) {
      // console.log('行',row,'列',column);
      let date = row.quitDate;
      //return moment(date).format("YYYY-MM-DD");
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    //清空列表
    clearUserForm() {
      this.dialogVisible = false; //隐藏弹框
      this.ruleForm = {};
      //单独清除下拉框
      this.value = "";
      this.value1 = "";
      this.value2 = "";
      this.value3 = "";
      this.value4 = "";
      this.value5 = "";
      this.value6 = "";
    },
    clearUserFormChange() {
      this.dialogVisibleChange = false;
      this.menulist();
      this.clearUserForm();
    },
    //是否在职和是否可登录的下拉框
    workIng() {
      api.smallLei({ dictCode: "isno" }).then((res) => {
        //console.log('大类下的小类：',res.data);
        if (res.data.code === 200) {
          this.options5 = res.data.result;
        }
      });
    },
    //查询所有机构的下拉框
    searchAllJigou() {
      api.searchAllOrg().then((res) => {
        //console.log('所有机构：',res.data);
        if (res.data.code === 200) {
          this.options3 = res.data.result;
        }
      });
    },
    //机构
    getjigouValue(value) {
      // console.log('jigou:',value);
      this.flagORG = true;
      this.ORG = value;
      if (value == "") {
        this.ruleForm.orgId = "";
      } else {
        this.ruleForm.orgId = value;
        api.AllOrgRole({ orgId: this.ruleForm.orgId }).then((res) => {
          console.log("组织机构下所有角色：", res.data);
          if (res.data.code === 200) {
            this.options4 = res.data.result;
          }
        });
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage1 = val;
      this.menulist(val);
    },
  },
};
</script>

<style lang="less" scoped>
.userguanli {
  // 搜索
  .sousuo {
    // background: red;
    display: flex;
    align-items: center;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .div1 {
      margin-right: 20px;
      .inputs {
        width: 231px;
        height: 33px;
        border: 1px solid #e5e5e5;
        margin-left: 5px;
      }
      .inputs:focus {
        outline: 1px solid #e5e5e5; //边框不用border，用outline
      }
      span {
        font-size: 14px;
        color: #909399;
        font-weight: 700;
      }
      .select {
        margin-left: 20px;
      }
    }
    .imgs {
      width: 49px;
    }
  }
  //   table
  .tables {
    position: relative;
    margin-left: 25px;
    margin-right: 40px;

    .tingyong {
      color: rgb(97, 97, 245);
    }
    .buts {
      width: 36px;
      height: 27px;
      margin: 0 10px;
    }
    .el-table {
      // max-height: 500px;
      // overflow: hidden;
      tr {
        th {
          font-size: 14px;
          border: none;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #909399;
        }
        td {
          border: none;
          text-align: center;
          font-size: 14px;
          color: #606266;
        }
      }
      .details {
        height: 27px;
        border-radius: 15px;
      }
    }
    .el-table::before {
      height: 0;
    }
    // .details {
    //   height: 30px;
    //   position: absolute;
    //   top: 12px;
    //   right: 1px;
    //   border-radius: 15px;
    // }
  }
  /deep/ .el-table .el-table__cell {
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  /deep/ th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  .totals {
    display: flex;
    justify-content: space-between;
    .left {
      p {
        font-size: 16px;
        display: inline-block;
        margin-right: 80px;

        margin-left: 50px;

        margin-top: 20px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
      // 按钮
      .buttons {
        .el-button {
          width: 130px;
          height: 40px;
          line-height: 40px;
          border-radius: 25px;
          margin-top: 20px;
          margin-right: 20px;
          margin-left: 56px;
          background: #d78673;
          color: #ffffff;
          font-size: 16px;
          padding: 0px;
        }
      }
    }
    .total {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 20px;
      margin-right: 50px;
      // page
      .pages {
        display: flex;
        align-items: center;
        .el-pagination {
          padding: 0;
          img {
            display: inline-block;
          }
          .lefts {
            margin-right: 25px;
          }
          .rights {
            float: right;
            margin-left: 25px;
          }
        }
      }
    }
  }
  // .el-input__inner {
  //   width: 50%;
  // }
  .lizhi {
    margin-left: -10px;
  }
  .account .el-input__inner {
    border: 1px solid #fff;
  }
  .el-form-item__label {
    margin-left: 60px;
  }
  .workState {
    margin: 70px 120px;
    span {
      margin-right: 15px;
    }
  }
  .sexState {
    position: absolute;
    right: 173px;
    top: 245px;
    span {
      margin-right: 15px;
    }
  }
  .jigouState {
    position: absolute;
    right: 173px;
    top: 180px;
    span {
      margin-right: 15px;
    }
  }
  .jueseState {
    position: absolute;
    left: 133px;
    top: 245px;
    span {
      margin-right: 15px;
    }
  }
  .dengState {
    position: absolute;
    left: 105px;
    top: 180px;
    span {
      margin-right: 15px;
    }
  }
  .workState1 {
    margin: 130px 120px;
    span {
      margin-right: 15px;
    }
  }
  // // 弹框
  // .el-dialog {
  //   border-radius: 18px;
  // }
  // .el-dialog__header {
  //   background: #eaeaea;
  //   border-radius: 18px 18px 0 0;
  // }
  // .el-dialog__close {
  //   color: transparent;
  //   background-image: url("../../assets/images/close.png");
  //   background-repeat: no-repeat;
  //   background-size: 14px;
  // }
  // .el-dialog__body {
  //   background: #eaeaea;
  //   border-radius: 0 0 18px 18px;
  //   padding: 10px 0px 0 0px;
  // }
  .accountdd {
    .el-dialog__header {
      background: #ffffff;
      border-radius: 18px 18px 0 0;
    }
    .el-dialog__body {
      background: #ffffff;
      border-radius: 0 0 18px 18px;
      padding: 10px 0px 0 0px;
    }
  }
  /deep/.el-input__inner {
    border-radius: 0;
    height: 33px !important;
    line-height: 33px !important;
  }
  /deep/.el-input__icon {
    height: 100%;
    width: 25px;
    text-align: center;
    transition: all 0.3s;
    line-height: 33px !important;
  }
  /deep/.el-form-item__label {
    color: #909399;
    text-align: center;
  }
  /deep/.el-dialog__body {
    padding: 0;
    margin-left: 2%;
  }
  /deep/.el-table td,
  .el-table th.is-leaf {
    border-bottom: none !important;
  }
  /deep/.el-table .cell {
    text-align: center !important;
  }
  /deep/.el-table thead tr th.is-leaf {
    border: none;
    border-right: none;
  }
  /deep/ .el-form-item {
    margin-bottom: 13px;
  }
  /deep/ .el-textarea__inner {
    width: 312px;
  }
}
</style>